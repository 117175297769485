<script setup>
import {
  definePageMeta,
  computed,
  reactive,
  ref,
  defineAsyncComponent,
  watch,
  onMounted,
  useRuntimeConfig,
} from '#imports';
import { object, string } from 'yup';
import { Form as VeeForm } from 'vee-validate';
import { useModal } from 'vue-final-modal';
import { useRoute, useRouter } from 'nuxt/app';

import { useUserStore } from '@/stores/user';
import { useAuthStore } from '@/stores/auth';

import { useFeedback } from '@/composables/useFeedback';
import { useAdult } from '@/composables/useAdult';
import { useSeo } from '@/composables/useSeo';
import { useFormHelp } from '@/composables/useFormHelp';
import { useWaitRequest } from '@/composables/useWaitRequest';
import { usePolicePopup } from '@/composables/usePolicePopup';
import { useMessagePopup } from '@/composables/useMessagePopup';

import { formatValues } from '@/utils/helpers';

import UiMaskInput from '@/components/ui/form/UiMaskInput.vue';
import UiButton from '@/components/ui/button/UiButton.vue';
import UiInput from '@/components/ui/form/UiInput.vue';
import FormLayout from '@/components/layout/forms/FormLayout.vue';
import BackgroundBlock from '@/components/blocks/BackgroundBlock.vue';
import Adult from '@/components/sections/Adult.vue';
import LoginFinish from '@/components/sections/auth/LoginFinish.vue';
import SideView from '@/components/layout/views/SideView.vue';
import { YandexSmartCaptcha } from '@gladesinger/vue3-yandex-smartcaptcha';

import { USER_ROLES } from '@/configs/user-roles';
import { police } from '@/configs/police';

definePageMeta({
  middleware: ['is-authorized'],
});

useSeo({ title: 'Авторизация | Winston.ru' });

const adultComposable = useAdult();
const { openFeedbackPopup } = useFeedback();
const userStore = useUserStore();
const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();
const formHelpComposable = useFormHelp();
const { requestInProgress, waitRequest } = useWaitRequest();
const { openPolicePopup } = usePolicePopup();
const configRuntime = useRuntimeConfig();
const { openMessagePopup } = useMessagePopup();

watch(
  route,
  (routeValue) => {
    const { query } = routeValue;

    if (process.client && query['direct-crm-ticket']) {
      waitRequest(() =>
        authStore
          .sendLoginByTicket({
            ticket: query['direct-crm-ticket'],
          })
          .then(() => {
            const pathRedirect = route.query?.authRedirect;
            const query = { ...route.query };

            if (pathRedirect) {
              router.push({ path: pathRedirect.toString(), query });

              return;
            }

            router.push('/');
          })
          .catch(() => {
            openMessagePopup(
              'Время действия ссылки истекло. Для продолжения работы с сайтом, пожалуйста, авторизуйтесь заново.',
              false,
            );
          }),
      );
    }
  },
  { immediate: true },
);

const isFinish = ref(false);
const isAdult = ref(false);
let formActions = null;
const yaCaptchaRef = ref(null);

checkAdultSectionState();
function checkAdultSectionState(value) {
  isAdult.value = value || adultComposable.isAdult();
}
function openRestorePasswordPopup() {
  const { open, close } = useModal({
    modalId: 'restorePasswordPopup',
    component: defineAsyncComponent(
      () => import('@/components/popups/RestorePassword.vue'),
    ),
    attrs: {
      closeModal: () => {
        close();
      },
    },
  });

  open();
}

watch(
  () => authStore.isAuthorized,
  (value) => {
    if (!value) {
      isFinish.value = false;
    }
  },
);

// <editor-fold desc="FORM">
const formData = reactive({
  phone: '',
  password: '',
});

const phoneData = computed({
  get() {
    return formData.phone;
  },
  set(detail = {}) {
    formData.phone = detail.masked;
  },
});
async function sendForm(_, actions) {
  formActions = actions;

  yaCaptchaRef.value.execute();
}

const success = (token) => {
  const data = {
    password: formData.password,
    phone: formatValues.phoneOnlyNumbers(formData.phone),
    captcha: token,
  };

  yaCaptchaRef.value.reset();

  waitRequest(() =>
    authStore
      .sendLogin(data)
      .then(() => {
        userStore.fetchUser().then((data) => {
          const role = data?.role;
          const id = data?.personalData?.mindboxId;

          if (role === USER_ROLES.NEW) {
            router.push('/auth/registration');
          } else {
            isFinish.value = true;

            sendAnalyticLoginSuccess();
            sendAnalyticAuthentication(id);
          }
        });
      })
      .catch(({ data }) => {
        sendAnalyticLoginError();

        formHelpComposable.checkErrors(data, formActions.setErrors);
      }),
  );
};
// </editor-fold>

//<editor-fold desc="Validation rules">
const validationSchema = object({
  phone: string().required().trim().lengthPhone(11),
  password: string().required(),
});
// </editor-fold>

//<editor-fold desc="ANALYTIC">
import { commonAnalytic } from '@/analytic/common';
import { authAnalytic } from '@/analytic/auth';

function sendAnalyticAuthentication(id) {
  commonAnalytic.authentication(id);
}

function sendAnalyticLoginSuccess() {
  authAnalytic.loginSuccess();
}

function sendAnalyticLoginError() {
  authAnalytic.loginError();
}

function sendAnalyticRegistrationStart() {
  authAnalytic.registrationStart();
}

onMounted(() => {
  authAnalytic.loginStart();
});
//</editor-fold>
</script>

<template>
  <SideView
    class="login-page"
    :class="{
      'login-page--is-adult': !isAdult,
      'login-page--finish': isFinish,
    }"
    is-equal
    is-right-full-height
    :is-collapsed="!isAdult || isFinish"
    :is-collapsed-start="!isAdult"
  >
    <template #background>
      <transition name="fade" mode="out-in">
        <UiButton
          v-if="!isFinish && isAdult"
          theme="white-small"
          class="login-page__button login-page__button--help"
          text="Помощь"
          @click="openFeedbackPopup"
        />
      </transition>

      <BackgroundBlock
        class="login-page__bg"
        target-class="side-view__part--left"
      />
    </template>

    <template #left>
      <transition name="fade" mode="out-in">
        <Adult
          v-if="!isFinish"
          class="login-page__adult"
          @change="checkAdultSectionState"
        />

        <LoginFinish v-else class="login-page__finish" />
      </transition>
    </template>

    <template #right>
      <client-only>
        <YandexSmartCaptcha
          ref="yaCaptchaRef"
          :site-key="configRuntime?.public?.recaptchaKey"
          :invisible="true"
          shield-position="bottom-right"
          @on-success="success"
        />
      </client-only>

      <FormLayout class="login-page__form" heading="Вход">
        <template #content>
          <VeeForm
            v-slot="{ isSubmitting }"
            :validation-schema="validationSchema"
            autocomplete="off"
            @submit="sendForm"
          >
            <UiMaskInput
              v-model="phoneData"
              :mask="'+7 (###) ### ## ##'"
              unmasked
              name="phone"
              label="Телефон"
              hint="Введите номер телефона"
              class="login-page__item"
              input-mode="numeric"
            />

            <div class="login-page__item-wrapper">
              <UiInput
                v-model="formData.password"
                name="password"
                label="Пароль"
                :type="'password'"
                is-toggle-password
                class="login-page__item"
                autocomplete="current-password"
                is-error-text-indented
              />

              <UiButton
                theme="link"
                class="login-page__button login-page__button--link"
                text="Забыли пароль?"
                @click="openRestorePasswordPopup"
              />
            </div>

            <div class="login-page__footer">
              <UiButton
                type="submit"
                class="login-page__button login-page__button--submit"
                text="войти"
                :is-disabled="isSubmitting || requestInProgress"
                @dblclick.prevent
              />

              <UiButton
                theme="second"
                to="/auth/registration"
                class="login-page__button login-page__button--registration"
                text="зарегистрироваться"
                @click="sendAnalyticRegistrationStart"
              />
            </div>
          </VeeForm>
        </template>
      </FormLayout>

      <div class="login-page__rules">
        <button
          type="button"
          class="login-page__button login-page__button--rules"
          @click="openPolicePopup(police.termsConditions)"
        >
          <span class="login-page__font login-page__font--rules">
            Пользовательское Соглашение
          </span>
        </button>

        <span
          class="login-page__font login-page__font--rules login-page__font--rules--light hidden-mobile"
          >|</span
        >

        <button
          type="button"
          class="login-page__button login-page__button--rules"
          @click="openPolicePopup(police.privacyPolicy)"
        >
          <span class="login-page__font login-page__font--rules">
            Политика о конфиденциальности
          </span>
        </button>
      </div>
    </template>
  </SideView>
</template>

<style scoped lang="scss">
.login-page {
  $parent: &;

  &__font {
    &--rules {
      @include subtitle-s;

      @include media-breakpoint-down(lg) {
        font-size: em(7);
      }

      @include media-breakpoint-down(sm) {
        font-size: em(10);
      }

      &--light {
        color: rgba($color-black-100, 0.5);
      }
    }
  }

  &__button {
    &--help {
      position: absolute;
      top: em(60);
      left: em(60);
      z-index: 2;
      min-width: em(160);

      @include media-breakpoint-down(lg) {
        min-width: em(116);
      }

      @include media-breakpoint-down(sm) {
        top: em(15);
        right: em(15);
        left: auto;
        min-width: em(104);
      }
    }

    &--link {
      position: absolute;
      right: 0;
      bottom: em(-4);

      @include media-breakpoint-down(lg) {
        bottom: 0;
      }

      @include media-breakpoint-down(sm) {
        bottom: em(-4);
      }
    }

    &--submit {
      width: calc(50% - #{em(8)});

      @include media-breakpoint-down(sm) {
        width: em(160);
      }
    }

    &--registration {
      width: calc(50% - #{em(8)});

      @include media-breakpoint-down(sm) {
        width: auto;
      }
    }

    &--rules {
      color: rgba($color-black-100, 0.5);
      transition: $time-default ease-out;
      transition-property: color;

      @include hover {
        color: $color-black-100;
      }
    }
  }

  &__form {
    padding-top: em(16);
    padding-bottom: em(16);
    margin: auto;

    &.form-layout {
      width: em(496);

      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }

    @include media-breakpoint-down(sm) {
      padding-top: 0;
      margin: 0;
    }
  }

  &__item-wrapper {
    position: relative;
  }

  &__footer {
    display: flex;
    gap: em(16);
    align-items: center;
    margin-top: em(48);

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  &__rules {
    position: relative;
    display: flex;
    gap: em(24);
    align-items: center;
    justify-content: center;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      display: none;
      height: 0.5px;
      content: '';
      background-color: rgba($color-black-100, 0.5);

      @include media-breakpoint-down(sm) {
        display: block;
      }
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      justify-content: flex-start;
      padding-top: em(20);
      margin-top: auto;
    }
  }
}
</style>
